
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        









































.app-cta {
  border: 1px solid rgba($c-pink-medium, 0.3);
  border-radius: 8px;
  padding: 33px 49px 24px 37px;

  @include mq('s') {
    width: 460px;
  }

  &__content {
    margin-top: 40px;

    @include mq('m') {
      margin-top: 0;
    }
  }

  &__fig {
    width: 85px !important;
    height: 34px;
    position: relative;
    margin: 0 !important;
  }

  &__img {
    width: 100% !important;
    height: 100%;
    position: absolute;
    object-fit: contain;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
  }

  &__app {
    a:first-child {
      margin-right: 16px;
    }
  }

  .store-img {
    display: inline-block;

    img {
      height: 4rem;
    }
  }
}
