
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































































































































































































































































































































.section-row,
[class*='section-row--'] {
  @include bg-color;

  overflow: hidden;
  padding-top: $spacing * 1.5;
  padding-bottom: $spacing * 2;

  &.is-midnight-darker,
  &.midnight-darker {
    background-color: $c-midnight-darker;

    .section-row__content * {
      color: $white;
    }
  }

  &[class*='--semi'] {
    position: relative;
    overflow: hidden;
  }

  &[class*='centered'] {
    .section-row__inner {
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .section-row__content {
      margin-top: 0;
      margin-left: 0;

      /* stylelint-disable max-nesting-depth */
      .wysiwyg {
        margin-bottom: 0;

        ::v-deep {
          p {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
      /* stylelint-enable max-nesting-depth */
    }
  }

  &[class*='is-dark'],
  &[class*='is-dark-blue'] {
    overflow: initial;

    /* stylelint-disable-next-line no-descending-specificity */
    ::v-deep {
      .link--revert {
        color: #fff;

        // prettier-ignore
        background-image: linear-gradient(#fff, #fff), linear-gradient(#d4007a, #d4007a);
      }
    }
  }

  @include mq(m) {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }

  @include mq(xl) {
    padding-top: $spacing * 5.5;
    padding-bottom: $spacing * 6.5;

    &[class*='--semi'] {
      flex-shrink: 0;
      width: 50%;

      // Preventing button from getting cut, will need better solution
      padding-left: 4px;
    }
  }
}

.section-row__inner {
  @include mq(l) {
    display: flex;

    [class*='section-row--'][class*='--reverse'] & {
      flex-direction: row-reverse;
    }
  }

  @include mq(xl) {
    [class*='section-row--'][class*='--semi'] & {
      flex-direction: row;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.section-row__type {
  @extend %fw-normal;

  display: flex;
  align-items: center;
  flex-basis: 100%;
  margin-bottom: $spacing * 2.5;
  text-transform: uppercase;

  .icon {
    width: auto;
    max-width: 5.5rem;
    height: 3rem;
    margin-right: $spacing * 0.5;
    fill: $c-blue-dark;
  }

  @include mq(l) {
    margin-bottom: 0;
    margin-left: col(7);

    [class*='section-row--'][class*='--reverse'] & {
      margin-left: 0;
    }
  }

  @include mq(xl) {
    [class*='section-row--'][class*='--semi'] & {
      margin-left: 0;
    }
  }
}

.section-row__picture {
  margin: 0 0 $spacing * 3;

  figure {
    margin: 0;
  }

  img {
    display: block;
    max-height: 25rem;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;

    .is-social & {
      max-height: none;
      margin-top: $spacing * -2.5;
    }
  }

  video {
    display: block;
    max-height: 25rem;
    margin: 0 auto;

    @include mq(m) {
      max-height: 45rem;
    }
  }

  [class*='section-row--'][class*='--semi'] & {
    margin-top: -$spacing * 2;

    img {
      width: auto;
      max-height: 46rem;
    }

    @include mq(m) {
      margin-bottom: 0;
    }
  }

  @include mq(l) {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: col(7);
    margin-bottom: 0;
    margin-left: col(-1);

    img {
      width: 100%;
      max-height: none;
    }

    [class*='section-row'][class*='--centered'] &,
    [class*='section-row--'][class*='--centered'] & {
      width: col(6);
      margin: 0;
      margin-right: col(0.5);
    }

    [class*='section-row--'][class*='--reverse'] & {
      margin-right: col(-1);
      margin-left: 0;
    }

    [class*='section-row--'][class*='--centered'][class*='--reverse'] & {
      margin-left: col(0.5);
    }
  }

  @include mq(xl) {
    [class*='section-row--'][class*='--semi'] & {
      position: absolute;
      z-index: -1;
      top: 5rem;
      right: 0;
      display: block;
      width: auto;
      height: 100%;
      max-height: 60rem;
      margin: 0;
      transform: translateX(40%);

      img {
        max-height: 100%;
        margin: 0 0 0 auto;
      }
    }
  }
}

.section-row__content {
  .is-dark & {
    color: $white;
  }

  .is-light-blue & {
    a {
      color: $white;
      font-weight: 500;
    }
  }

  @include mq(m) {
    [class*='section-row--'][class*='--semi'] & {
      width: col(5);
    }
  }

  @include mq(l) {
    width: col(5);
    margin-top: $spacing * 1.5;
    margin-left: col(1);

    [class*='section-row--'][class*='--reverse'] & {
      width: col(5);
      margin-right: col(1);
      margin-left: 0;
    }

    [class*='section-row--'][class*='--semi'] & {
      margin-left: 0;
    }
  }

  @include mq(xl) {
    [class*='section-row--'][class*='--semi'] & {
      width: inside-col(6, 5);
      margin-right: 0;
    }
  }
}

.section-row__content__logo {
  margin-bottom: $spacing * 1.5;
}

.section-row__content__text {
  @include mq(xl) {
    margin-bottom: $spacing * 2;

    [class*='section-row--'][class*='--semi'] & {
      width: inside-col(5, 3);
    }
  }
}

.section-row__content__link {
  margin-top: $spacing;
}

.section-row__content__category {
  @extend %fw-medium;

  margin-bottom: $spacing;
  font-family: $ff-alt;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-row__content__title {
  margin: 0 0 $spacing;

  @include mq(l) {
    margin-bottom: $spacing * 1.5;
  }

  @include mq($from: l, $until: xxxl) {
    font-size: 4rem;
  }
}

.section-row__content__new,
[class*='section-row.section-row__content__new--'] {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 44rem;
  margin-top: $spacing * 2;
  padding: 3rem;
  border: 1px solid $c-blue-dark;
  border-radius: 10px;

  @include mq(m) {
    width: 50rem;
    padding: 4rem;
  }
}

.section-row__content__new__label {
  position: absolute;
  top: 0;
  display: inline-block;
  margin-left: -$spacing / 2;
  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.4rem;
  background-color: $c-pink-medium;
  border-radius: 3px;
  transform: translateY(-50%);
}

.section-row__content__new__logo {
  width: 10rem;
  height: 10rem;
  margin-right: $spacing;
  margin-bottom: 0;

  @include mq(m) {
    margin-right: $spacing * 2;
  }
}

.section-row__content__new__title {
  margin: $spacing / 4 0;
  font-size: 2.4rem;
}

.section-row__content__new__excerpt {
  margin: 0;
  font-size: 1.5rem;
}

.section-row__content__new__offer {
  @extend %text-uppercase;

  margin: 0;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 2.4rem;
  font-weight: 900;
  line-height: 1.3;
}

.section-row__content__new__link {
  margin-top: $spacing / 2;
}

.section-row__content__cards {
  margin-top: 100px;
}

.section-row__content__icon {
  margin-top: 40px;
  margin-bottom: 50px;

  @include mq(xl) {
    width: 50rem;
    margin-top: 0;
  }
}

.section-row__content__icon--img {
  max-width: 23rem;
}

.section-row__content__btn {
  margin-top: 40px;

  @include mq(xl) {
    margin-top: 0;
  }
}
